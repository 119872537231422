import React, { useEffect } from 'react'

type InputProps = {
  label: string
  model: string
  field: string
  type: string
  id: string
  required?: boolean
  pattern?: string
  value?: string | number
  className?: string
  inputClassName?: string
  checkedValues?: string[]
  placeholder?: string
}

const Input: React.FunctionComponent<InputProps> = ({
  label,
  model,
  field,
  type,
  id,
  required,
  pattern,
  value,
  className,
  inputClassName,
  checkedValues = [],
  placeholder,
}) => {
  const [currentValue, setCurrentValue] = React.useState<string | number>(
    value || ''
  )
  const [selectedValue, setSelectedValue] = React.useState<string | number>(
    value || checkedValues[0] || ''
  )

  let onCheck = () => {}

  onCheck = () => {
    const value = checkedValues.find((v) => v !== selectedValue) as
      | string
      | number
    setSelectedValue(value)
  }

  useEffect(() => {
    setCurrentValue(value || '')
  }, [value])

  return (
    <div
      className={`${className} ` + `mb-4 ${type == 'hidden' ? 'hidden' : ''}`}
    >
      {type !== 'checkbox' && (
        <label htmlFor={id} className="block text-gold text-sm font-bold mb-2">
          {label}
        </label>
      )}
      <input
        pattern={pattern}
        required={required}
        type={type}
        id={id}
        placeholder={placeholder}
        className={
          inputClassName ||
          'shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline'
        }
        onChange={(e) => setCurrentValue(e.target.value)}
        onClick={(e) => onCheck()}
        {...(type === 'checkbox'
          ? { checked: selectedValue === checkedValues[1] }
          : { name: `${model}${field}`, value: currentValue })}
      />
      {type == 'checkbox' && (
        <>
          <input
            type="hidden"
            name={`${model}${field}`}
            value={selectedValue}
          />
          <label
            htmlFor={id}
            className="block text-gold text-sm pl-2 lg:text-lg"
          >
            {label}
          </label>
        </>
      )}
    </div>
  )
}

export default Input
