import React from 'react'
import Form from '../../components/form/form'
import Input from '../../components/form/input'

const SessionNew = ({ session }) => {
  return (
    <Form
      title="Sign in"
      action="/admin/sessions"
      submitLabel="Sign in"
      method="post"
      success="/"
    >
      <Input
        type="password"
        model="session"
        field="[password]"
        label="Password"
        id="session-password"
      />
    </Form>
  )
}

export default SessionNew
