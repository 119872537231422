import React from 'react'
import InvitationForm from '../../components/invitation/form'

const EditInvitation: React.FunctionComponent<InvitationProps> = ({
  invitation,
  invitees,
  mapsKey,
  invitationAddress,
  invitationInvitees,
}) => {
  return (
    <InvitationForm
      invitationAddress={invitationAddress}
      invitationInvitees={invitationInvitees}
      invitation={invitation}
      invitees={invitees}
      mapsKey={mapsKey}
    />
  )
}

export default EditInvitation
