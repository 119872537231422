import React from 'react'
import Form from '../../components/form/form'
import Input from '../../components/form/input'

const RsvpSearch = ({ rsvp, responses }) => {
  const { id } = rsvp || {}
  const queryPrams = window.location.search
  const searchParams = new URLSearchParams(queryPrams)
  const token = searchParams.get('token')
  const not_found = searchParams.get('not_found')
  return (
    <Form
      title="RSVP"
      action="/rsvp/search"
      submitLabel="Search"
      method="post"
      success=""
      error="Your search did not return any results. Please try a different name or contact Annie or Ian for help."
      errorRedirect={not_found == 'true'}
    >
      <Input
        className="mt-4"
        type="text"
        model="rsvp"
        field="[first_name]"
        label="Enter your first name"
        id="rsvpsearch-first-name"
      />
      <Input
        type="text"
        model="rsvp"
        field="[last_name]"
        label="Enter your last name"
        id="rsvpsearch-last-name"
      />
    </Form>
  )
}

export default RsvpSearch
