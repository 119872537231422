import React from 'react'

const InvitationIndex = ({ invitations, invitationsTotals }) => {
  const csrf =
    document
      .querySelector("meta[name='csrf-token']")
      ?.getAttribute('content') || ''

  const handleSentInvite = (invitation_id, sent) => async (event) => {
    event.preventDefault()
    if (sent) return
    const data = new FormData()
    data.append('invitation[status]', 'sent')
    const target = event.currentTarget
    const response = await fetch(`/invitations/${invitation_id}`, {
      method: 'PATCH',
      headers: {
        'X-CSRF-Token': csrf,
      },
      body: data,
    })

    if (response.ok) {
      target.innerText = '✔'
    }
  }
  return (
    <div>
      <h1 className="text-3xl text-gold border-gold border-b-2 mx-8 mt-8">
        Invitations
      </h1>

      {invitations.length > 0 && (
        <div className="p-4 m-8 bg-pink shadow-md shadow-green rounded">
          <div className="grid grid-cols-10 gap-4 font-bold p-2">
            <div>Invitee</div>
            <div>Address</div>
            <div>Tier</div>
            <div>Status</div>
            <div>Invitee Count</div>
            <div>Going Count</div>
            <div>Sent</div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          {invitations.map((invitation, i) => (
            <div
              className="raised-panel-row grid grid-cols-10 gap-4 p-2"
              key={invitation.id}
            >
              <div key={`${i}-invitee`}>{invitation.inviteeName}</div>
              <div key={`${i}-address`}>{invitation.hasAddress ? '✔' : ''}</div>
              <div key={`${i}-tier`}>{invitation.tier}</div>
              <div key={`${i}-status`}>{invitation.status}</div>
              <div key={`${i}-count`}>{invitation.inviteeCount}</div>
              <div key={`${i}-going-count`}>{invitation.goingCount}</div>
              <div key={`${i}-sent hover:bg-gold`}>
                {invitation.status !== 'pending' ? (
                  '✔'
                ) : (
                  <div
                    key={`${i}-sent-button`}
                    className="border-2 border-gold rounded shadow-green shadow text-center text-gold hover:bg-green hover:text-pink min-h-7"
                    onClick={handleSentInvite(
                      invitation.id,
                      invitation.status === 'sent'
                    )}
                  >
                    {invitation.status === 'sent' ? '✔' : ''}
                  </div>
                )}
              </div>
              <a
                className="no-underline"
                href={`/invitations/${invitation.id}`}
              >
                <div
                  key={`${i}-show`}
                  className="border-2 border-gold rounded shadow-green shadow text-center"
                >
                  Print
                </div>
              </a>
              <a
                className="no-underline"
                href={`/invitations/${invitation.id}/edit`}
              >
                <div
                  key={`${i}-edit`}
                  className="border-2 border-gold rounded shadow-green shadow text-center"
                >
                  Edit
                </div>
              </a>
            </div>
          ))}
          <div
            className="raised-panel-row grid grid-cols-10 gap-4 p-2 font-bold"
            key="invitations-totals"
          >
            <div>Totals:</div>
            <div></div>
            <div></div>
            <div></div>
            <div>{invitationsTotals.invitedCount}</div>
            <div>{invitationsTotals.goingCount}</div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <a
            className="shadow-md shadow-green bg-pink text-green float-right border-2 border-gold rounded my-8 p-2"
            href="/invitations/new"
          >
            <div>Add Invitation</div>
          </a>
        </div>
      )}
    </div>
  )
}

export default InvitationIndex
