import React, { useState } from 'react'

type Invitee = {
  firstName: string
  lastName: string
  id: number
}

type IndexProps = {
  invitees: Invitee[]
}

const InviteeIndex = ({ invitees }: IndexProps) => {
  const csrf =
    document
      .querySelector("meta[name='csrf-token']")
      ?.getAttribute('content') || ''

  const [currentInvitees, setCurrentInvitees] = useState(invitees)
  const [successResponse, setSuccessResponse] = useState(false)
  const [errorResponse, setErrorResponse] = useState(false)
  const [successfulMessage, setSuccessfulMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const refreshInvitees = async () => {
    const response = await fetch('/invitees', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json', // This is the type of data you're expecting to receive from the server.
        'X-CSRF-Token': csrf,
      },
    })

    if (response.ok) {
      const responseData = await response.json()
      setCurrentInvitees(responseData)
    }
  }

  const handleDelete = (id: number) => async () => {
    const response = await fetch(`/invitees/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf,
      },
    })

    const responseData = await response.json()

    if (response.ok) {
      await refreshInvitees()
      setSuccessResponse(true)
      setSuccessfulMessage(
        `successfully deleted ${responseData.first_name} ${responseData.last_name}!`
      )
      refreshInvitees()
      setTimeout(() => {
        setSuccessfulMessage('')
        setSuccessResponse(false)
      }, 1000)
    } else {
      setErrorResponse(true)
      setErrorMessage(responseData.errors.join(', '))
      setTimeout(() => {
        setErrorMessage('')
        setErrorResponse(false)
      })
    }
  }

  return (
    <div>
      <div className="ml-2 mt-2 mb-8">
        <h1 className="text-3xl">Invitees</h1>
      </div>
      {successResponse && (
        <div className="text-base text-green-950">{successfulMessage}</div>
      )}
      {errorResponse && (
        <div className="text-base text-red-950">{errorMessage}</div>
      )}
      {currentInvitees.length > 0 && (
        <div className="p-4 w-3/4 m-auto bg-pink shadow-md shadow-green rounded">
          <div className="grid grid-cols-4 gap-4 font-bold p-2">
            <div>First Name</div>
            <div>Last Name</div>
            <div></div>
            <div></div>
          </div>
          {currentInvitees.map((invitee, i) => (
            <div
              className="raised-panel-row grid grid-cols-4 gap-4 p-2"
              key={invitee.id}
            >
              <div key={`${i}-first_name`}>{invitee.firstName}</div>
              <div key={`${i}-last_name`}>{invitee.lastName}</div>
              <a className="no-underline" href={`/invitees/${invitee.id}/edit`}>
                <div
                  key={`${i}-edit`}
                  className="border-2 border-gold rounded shadow-green shadow text-center"
                >
                  Edit
                </div>
              </a>
              <div
                key={`${i}-delete`}
                className="border-2 border-gold rounded shadow-green shadow text-center"
              >
                {' '}
                <div onClick={handleDelete(invitee.id)}>Delete</div>
              </div>
            </div>
          ))}
          <a
            className="shadow-md shadow-green float-right border-2 border-gold text-green bg-pink rounded p-2 my-8"
            href="/invitees/new"
          >
            <div>Add Invitee</div>
          </a>
        </div>
      )}
    </div>
  )
}

export default InviteeIndex
