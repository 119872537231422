import React, { useState } from 'react'
import Autocomplete from 'react-google-autocomplete'
import Input from '../form/input'

type AddressProps = {
  mapsKey: string
  currentAddress: Address
  setCurrentAddress: (address: Address) => void
}

export type Address = {
id?: number | string
  street1: string
  street2: string
  city: string
  state: string
  zip: string
}

type GoogleAddress = {
  street_number: {
    long_name: string
    short_name: string
  }
  route: {
    long_name: string
    short_name: string
  }
  locality: {
    long_name: string
    short_name: string
  }
  administrative_area_level_1: {
    long_name: string
    short_name: string
  }
  postal_code: {
    long_name: string
    short_name: string
  }
}

const buildAddressObject = (addressAtrributes): Address => {
  const address: GoogleAddress = addressAtrributes.reduce(
    (addressObj, attribute) => {
      addressObj[attribute.types[0]] = attribute
      return addressObj
    },
    {}
  )
  const currentAddress: Address = {
    street1: `${address.street_number.long_name} ${address.route.long_name}`,
    street2: '',
    city: address.locality.long_name,
    state: address.administrative_area_level_1.short_name,
    zip: address.postal_code.long_name,
  }

  return currentAddress
}

const AddressForm: React.FunctionComponent<AddressProps> = ({
  mapsKey,
  currentAddress,
  setCurrentAddress,
}) => {
  const onPlaceSelected = (place) => {
    setCurrentAddress({
      ...currentAddress,
      ...buildAddressObject(place.address_components),
    })
  }

  return (
    <>
      <Autocomplete
        apiKey={mapsKey}
        onPlaceSelected={onPlaceSelected}
        options={{
          componentRestrictions: { country: 'us' },
          types: ['address'],
          fields: ['address_components', 'geometry'],
        }}
      />

      <Input
        type="hidden"
        model="invitation"
        field="[address_attributes][id]"
        value={currentAddress.id}
        label="Id"
        id="invitation_address_attributes_id"
      />

      <Input
        label="Street"
        model="invitation"
        field="[address_attributes][street1]"
        type="text"
        id="invitation_address_attributes_street1"
        required
        value={currentAddress.street1}
      />

      <Input
        label="Street 2"
        model="invitation"
        field="[address_attributes][street2]"
        type="text"
        id="invitation_address_attributes_street2"
        value={currentAddress.street2}
      />

      <Input
        label="City"
        model="invitation"
        field="[address_attributes][city]"
        type="text"
        id="invitation_address_attributes_city"
        required
        value={currentAddress.city}
      />

      <Input
        label="State"
        model="invitation"
        field="[address_attributes][state]"
        type="text"
        id="invitation_address_attributes_state"
        required
        value={currentAddress.state}
      />

      <Input
        label="Zip"
        model="invitation"
        field="[address_attributes][zip]"
        type="text"
        id="invitation_address_attributes_attributes_zip"
        required
        value={currentAddress.zip}
      />
    </>
  )
}

export default AddressForm
