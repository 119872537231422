import React, { useEffect } from 'react'

type FormProps = {
  title: string
  action: string
  method: string
  success: string
  error: string
  children: React.ReactNode
  submitLabel: string
  errorRedirect?: boolean
  submitCallback?: () => void
  reset?: () => void
}

const Form: React.FunctionComponent<FormProps> = ({
  title,
  action,
  method,
  children,
  success,
  error,
  submitLabel,
  errorRedirect,
  reset,
  submitCallback,
}) => {
  const csrf =
    document
      .querySelector("meta[name='csrf-token']")
      ?.getAttribute('content') || ''

  const [successfulMessage, setSuccessfulMessage] = React.useState('')
  const [successResponse, setSuccessResponse] = React.useState(false)
  const [errorResponse, setErrorResponse] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')

  useEffect(() => {
    if (errorRedirect) {
      setErrorResponse(true)
      setErrorMessage(
        "We couldn't find your RSVP. Please try searching by name or contact Annie or Ian for help."
      )
      setTimeout(() => {
        setErrorMessage('')
        setErrorResponse(false)
      }, 5000)
    }
  }, [])

  const handleSubmit = async (event) => {
    event.preventDefault()
    const target = event.currentTarget
    const data = new FormData(target)

    try {
      const response = await fetch(target.action, {
        method: method.toUpperCase(),
        headers: {
          'X-CSRF-Token': csrf,
        },
        body: data,
      })

      if (response.redirected) {
        window.location.href = response.url
        return
      }

      const responseData = await response.json().catch((err) => {
        throw new Error('Invalid JSON response')
      })

      if (response.ok) {
        setSuccessResponse(true)
        setSuccessfulMessage(success)
        setTimeout(() => {
          setSuccessfulMessage('')
          setSuccessResponse(false)
          if (method === 'post') {
            if (reset) reset()
            target.reset()
          }
          window.location.href = '/'
        }, 5000)
      } else {
        setErrorResponse(true)
        console.error(
          responseData.errors ? responseData.errors.join(', ') : 'Unknown error'
        )
        setErrorMessage(error)
        setTimeout(() => {
          setErrorMessage('')
          setErrorResponse(false)
        }, 5000)
      }
    } catch (err) {
      setErrorResponse(true)
      setErrorMessage(error || 'An unexpected error occurred')
      console.error(err)
      setTimeout(() => {
        setErrorMessage('')
        setErrorResponse(false)
      }, 5000)
    }
  }

  return (
    <div className="h-dvh text-center lg:text-left">
      <div className="p-8 m-8 rounded">
        <div className="p-4 lg:p-0">
          {title && (
            <>
              <h1 className="text-4xl text-gold lg:text-3xl">{title}</h1>
              <div className="w-full pb-2 border-b-2 border-gold mb-4"></div>
            </>
          )}
          {successResponse && (
            <div className="text-base text-green bg-white border-green">
              <span className="font-bold text-lg text-green-600 rounded p-2">
                Success!{' '}
              </span>
              {successfulMessage}
            </div>
          )}
          {errorResponse && (
            <div className="text-base text-red bg-pink border-red rounded p-2">
              {errorMessage}
            </div>
          )}
          <form
            role="form"
            action={action}
            acceptCharset="UTF-8"
            method={method}
            onSubmit={handleSubmit}
          >
            <div className="flex items-center w-full flex-col lg:items-start">
              {children}
            </div>
            <button
              type="submit"
              className="mt-4 bg-blue-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline border-2 border-gold shadow shadow-green"
            >
              {submitLabel}
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Form
