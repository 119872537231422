import React from "react"
import InviteeForm from "../../components/invitee/form"

export type Invitee = {
  first_name: string;
  last_name: string;
  email: string;
  id: number;
  phone?: string;
}

type InviteeEditProps = {
  invitee: Invitee;
}

const InviteeEdit: React.FunctionComponent<InviteeEditProps> = ({invitee}) => {
  return (
    <InviteeForm first_name={invitee.first_name} last_name={invitee.last_name} id={invitee.id} />
  )
}

export default InviteeEdit