import React from 'react'
import InvitationForm from '../../components/invitation/form'

type Invitee = {
  id: number
  name: string
}

type Invitation = {
  id?: number
  invitees: Invitee[]
}

type InvitationProps = {
  invitation: Invitation
  invitees: Invitee[]
  mapsKey: string
}

const NewInvitation: React.FunctionComponent<InvitationProps> = ({
  invitation,
  invitees,
  mapsKey,
}) => {
  return (
    <InvitationForm id={invitation?.id} invitees={invitees} mapsKey={mapsKey} />
  )
}

export default NewInvitation
