import React, { useEffect, useState } from 'react'
import { Cloudinary } from '@cloudinary/url-gen'
import { AdvancedImage } from '@cloudinary/react'

const Home = () => {
  const tabs = ['Logistics', 'Welcome Event', 'Party Deets']
  const [currentTab, setCurrentTab] = useState('Logistics')
  const [isVisible, setIsVisible] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const cld = new Cloudinary({ cloud: { cloudName: 'dqzwg960e' } })

  useEffect(() => {
    setIsVisible(true)
  }, [])

  const viennaImg = cld
    .image('vienna-cropped_eq4mg1') //vienna_qgagq8
    .format('auto')
    .quality('auto')
  const lastStrawImg = cld
    .image('last-straw_pa3yaz')
    .format('auto')
    .quality('auto')
  const tsPartyImg = cld.image('ts-party_xagueo').format('auto').quality('auto')
  const berlinImg = cld.image('berlin_vfiymk').format('auto').quality('auto')
  const berlin2Img = cld.image('berlin-2_fq2twj').format('auto').quality('auto')
  const whitneyImg = cld.image('whitney_pqjkjp').format('auto').quality('auto')

  const handleTabChange = (tab) => {
    setCurrentTab(tab)
    setIsMenuOpen(false)
  }

  return (
    <div>
      <div className="h-14 bg-green drop-shadow-lg sticky top-0">
        <div className="flex justify-between items-center p-4">
          <div className="text-gold">
            <a href="/" className="font-bold">
              I & A
            </a>
          </div>
          <span className="text-gold-100 md:hidden">{currentTab}</span>
          <button className="md:hidden" onClick={toggleMenu}>
            <div className="space-y-2">
              <span className="block w-8 h-0.5 bg-gold"></span>
              <span className="block w-8 h-0.5 bg-gold"></span>
              <span className="block w-8 h-0.5 bg-gold"></span>
            </div>
          </button>
          <div className="hidden md:flex md:justify-center">
            {tabs.map((tab) => (
              <div
                key={tab}
                className={`font-bold text-gold-100 cursor-pointer p-2 md:mx-2 ${
                  currentTab === tab ? 'underline text-gold-200' : ''
                }`}
                onClick={() => handleTabChange(tab)}
              >
                {tab}
              </div>
            ))}
          </div>
        </div>
        <div
          className={`absolute w-full bg-green z-10 md:hidden transition-all duration-300 slide-in ${
            isMenuOpen ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'
          } overflow-hidden`}
        >
          {tabs.map((tab) => (
            <div
              key={tab}
              className={`text-gold-100 p-4 text-center border-b border-gold-100 ${
                currentTab === tab ? 'bg-gold-200 text-green' : ''
              }`}
              onClick={() => handleTabChange(tab)}
            >
              {tab}
            </div>
          ))}
        </div>
      </div>
      <div className="bg-green container mx-auto mt-10">
        <div className="rounded shadow-green shadow">
          <div className="text-center text-gold">
            <div className="text-4xl mb-4">Annie and Ian Get Married</div>
            <div className="font-san-serif">
              <div className="text-2xl mb-4">September 7, 2024</div>

              <div className="flex flex-row justify-center p-8 overflow-hidden h-160">
                {[
                  tsPartyImg,
                  lastStrawImg,
                  whitneyImg,
                  berlinImg,
                  viennaImg,
                  berlin2Img,
                ].map((img, index) => (
                  <div key={index} className="w-1/6 h-full overflow-hidden">
                    <AdvancedImage
                      cldImg={img}
                      alt="Annie and Ian"
                      className={`mx-2 w-full h-full object-cover object-center fade-in ${
                        isVisible ? 'visible' : ''
                      }`}
                      style={{ maxHeight: '100%' }}
                    />
                  </div>
                ))}
              </div>

              <div className="details flex justify-center pb-8 mb-8">
                <div className="w-1/2">
                  {currentTab === 'Welcome Event' && (
                    <>
                      <div className="text-4xl font-bold mb-4">
                        Welcome Event
                      </div>
                      <div className="text-2xl font-bold mb-4">Friday, 9/6</div>
                      <span>-----</span>
                      <div className="text-2xl font-bold mb-4">
                        Cocktails & Games
                      </div>
                      <div className="text-xl mb-4">4:30pm - 6:30pm</div>
                      <a
                        href="https://maps.app.goo.gl/TrwQc5CweqJWPNij8"
                        className="link text-gold-100"
                        target="_blank"
                      >
                        <div className="text-xl mb-4 font-body">
                          Colorado Chautauqua
                        </div>
                        <div className="text-xl mb-4 font-body">
                          900 Baseline Road, Boulder, CO 80302
                        </div>
                      </a>
                      <div className="text-lg mb-4 font-body">
                        If you're in town early, we'd love to see you! Join us
                        for a casual welcome event at Chautauqua. We'll have
                        cocktails, NA beverages, and lawn games. Your best
                        croquet attire, overalls, or creative hats are
                        encouraged.
                      </div>
                      <div className="text-lg mb-4 font-body">
                        All ages welcome.
                      </div>
                    </>
                  )}

                  {currentTab === 'Party Deets' && (
                    <>
                      <div className="text-4xl font-bold mb-4">Party Deets</div>
                      <div className="text-2xl font-bold mb-4">
                        Saturday, 9/7
                      </div>
                      <span>-----</span>
                      <div className="text-2xl font-bold mb-4">Ceremony</div>
                      <div className="text-xl mb-8">4:30pm - 5:30pm</div>
                      <div className="text-lg mb-4 font-body">
                        All ages welcome.
                      </div>
                      <a
                        href="https://maps.app.goo.gl/sSpsRDG29Y1bzAe88"
                        className="link text-gold-100"
                        target="_blank"
                      >
                        <div className="text-xl mb-4 font-body">
                          Sunrise Amphitheater
                        </div>
                        <div className="text-xl mb-4 font-body">
                          1198 Flagstaff Dr, Boulder, CO 80302
                        </div>
                      </a>

                      <span>-----</span>
                      <div className="text-2xl font-bold mb-4">Reception</div>
                      <div className="text-xl mb-8">6:00pm - 12:00am</div>
                      <div className="text-lg mb-4 font-body">21+ only.</div>
                      <a
                        href="https://maps.app.goo.gl/V6mUCCEV1SMU7SG88"
                        target="_blank"
                        className="link text-gold-100 text-lg"
                      >
                        <div className="text-xl mb-4 font-body">Corrida</div>
                        <div className="text-xl mb-4 font-body">
                          1023 Walnut St STE 400, Boulder, CO 80302
                        </div>
                      </a>
                      <span className="pt-4 display-block">
                        --------------------
                      </span>

                      <div className="text-2xl font-bold mb-4">Attire</div>
                      <div className="text-lg mb-4 font-body">
                        Cocktail attire: suits and ties, cocktail dresses, or
                        dressy separates. We recommend bringing a light jacket
                        or layers for the ceremony as Colorado weather can be
                        unpredictable.{' '}
                      </div>
                      <span>-----</span>
                      <div className="text-2xl font-bold mb-4">Other</div>
                      <div className="text-lg mb-4 font-body">
                        Due to venue restrictions, the reception at Corrida is
                        21+ only. Guests of all ages are welcome at our{' '}
                        <span
                          className="link text-gold-100 cursor-pointer"
                          onClick={() => setCurrentTab('Welcome Event')}
                        >
                          Friday welcome event
                        </span>{' '}
                        and at our ceremony. For any questions, please reach out
                        to us directly.
                      </div>
                    </>
                  )}

                  {currentTab === 'Logistics' && (
                    <>
                      <div className="text-3xl font-bold mb-4">Logistics</div>
                      <div className="text-2xl font-bold mb-4">
                        Wedding Day Transportation
                      </div>
                      <div className="text-lg mb-4 font-body">
                        <div className="mb-4">
                          For guests not staying at Chataqua please carpool as
                          much as you are able. There is very limited parking so
                          the fewer cars the better. Ceremony starts at 4:30 so
                          please plan accordingly.
                        </div>
                        <div className="mb-4">
                          For guests who are staying at Chatqua there will be
                          GreenRide shuttles arriving at 3:45 PM at The bottom
                          of the steps in front of the Chataqua Dining Hall. The
                          shuttles depart at 4 for Sunrise Ampitheater. The
                          shuttles will also be transporting Chataqua guests to
                          Corrida after the Ceremony.
                        </div>
                        <div className="mb-4">
                          <span>-----</span>
                          THERE ARE NO SHUTTLES BACK TO CHATAQUA AFTER THE
                          RECEPTION.
                          <span>-----</span>
                        </div>
                        Please arrange your own transportation back to Chataqua.
                        It is a short ride from Corrida so getting a Lyft, Uber,
                        or even walking back for the more adventurous are all
                        great options!
                      </div>
                      <span>-----</span>
                      <div className="text-2xl font-bold mb-4">
                        Accommodations
                      </div>
                      <div className="text-lg mb-4 font-body">
                        We have courtesy hotel blocks at the{' '}
                        <a
                          href="https://www.marriott.com/en-us/hotels/vbocg-residence-inn-boulder/overview/?scid=f2ae0541-1279-4f24-b197-a979c79310b0"
                          className="link text-gold-100"
                        >
                          Residence Inn Boulder
                        </a>{' '}
                        ($239/night) and at the{' '}
                        <a
                          href="https://www.marriott.com/en-us/hotels/denbd-courtyard-boulder/overview/?scid=f2ae0541-1279-4f24-b197-a979c79310b0"
                          className="link text-gold-100"
                        >
                          Marriott Courtyard Boulder
                        </a>{' '}
                        ($209 - $229/night). Reservations must be made before
                        August 9, 2024. Please ask Ian or Annie for the booking
                        code. We also have a small number of rooms available at
                        Chautauqua.
                      </div>
                      <div className="text-lg mb-4 font-body">
                        There are many other hotel and AirBnb options in
                        Boulder. If you need additional recs, let us know!
                      </div>
                      <span>-----</span>
                      <div className="text-2xl font-bold mb-4">Registry</div>
                      <div className="text-lg mb-4 font-body">
                        We are overjoyed to celebrate with you and your presence
                        is all we need. If you'd like to give a gift, we are are
                        registered at{' '}
                        <a
                          href="https://www.zola.com/registry/annieandian2024"
                          className="link text-gold-100"
                        >
                          Zola
                        </a>{' '}
                        and at{' '}
                        <a
                          href="https://heathceramics.myshopify.com/apps/registry/ian-and-annie-get-hitched?shared_url=true"
                          className="link text-gold-100"
                        >
                          Heath Ceramics.
                        </a>
                      </div>
                      <span>-----</span>
                      <div className="text-2xl font-bold mb-4">
                        Accessibility
                      </div>
                      <div className="text-lg mb-4 font-body">
                        Our ceremony and reception locations are accessible by
                        wheelchair. The ceremony at Sunrise Amphitheater is
                        outdoors, close to parking, and accessible by a gently
                        sloping path. The reception at Corrida is on the 4th
                        floor of a building with elevator access. If you have
                        specific accessibility needs or questions, please reach
                        out.
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-14 sticky top-0">
        <div className="flex justify-between items-center p-4 pb-8">
          {currentTab !== tabs[0] ? (
            <div
              className="text-gold-100 cursor-pointer p-2 flex flex-row"
              onClick={() =>
                handleTabChange(tabs[tabs.indexOf(currentTab) - 1])
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m18.75 4.5-7.5 7.5 7.5 7.5m-6-15L5.25 12l7.5 7.5"
                />
              </svg>
              {tabs[tabs.indexOf(currentTab) - 1]}
            </div>
          ) : (
            <div></div>
          )}
          {currentTab !== tabs[tabs.length - 1] && (
            <div
              className="text-gold-100 cursor-pointer p-2 flex flex-row"
              onClick={() =>
                handleTabChange(tabs[tabs.indexOf(currentTab) + 1])
              }
            >
              {tabs[tabs.indexOf(currentTab) + 1]}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="size-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5"
                />
              </svg>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Home
