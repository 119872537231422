import React from 'react'
import mount from './mount'
import Home from './pages/home/show'
import InviteeNew from './pages/invitee/new'
import InviteeIndex from './pages/invitee/index'
import InviteeEdit from './pages/invitee/edit'
import InvitationShow from './pages/invitation/show'
import InvitationNew from './pages/invitation/new'
import InvitationIndex from './pages/invitation/index'
import InvitationEdit from './pages/invitation/edit'
import RsvpNew from './pages/rsvp/new'
import RsvpEdit from './pages/rsvp/edit'
import RsvpSearch from './pages/rsvp/search'
import SessionNew from './pages/session/new'

const App = () => <h1>Hello from React!</h1>

mount({
  App,
  Home,
  InviteeNew,
  InviteeIndex,
  InviteeEdit,
  InvitationShow,
  InvitationNew,
  InvitationEdit,
  InvitationIndex,
  RsvpNew,
  RsvpEdit,
  RsvpSearch,
  SessionNew,
})
