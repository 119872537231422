import React from 'react'
import { Invitee } from '../invitee/edit'
import { QRCodeSVG } from 'qrcode.react'

type Address = {
  street1: string
  street2?: string
  city: string
  state: string
  zip: string
}

type Invitation = {}

type InvitationShowProps = {
  invitation: Invitation
  address: Address
  sendToName: string
}

type SquigglyProps = {
  x1: number
  y1: number
  x2: number
  y2: number
  flipped?: boolean
}

const calculate_distance = (
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  vertical: boolean
): number => {
  let distance = 0
  if (vertical) {
    distance = Math.abs(y2 - y1)
  } else {
    distance = Math.abs(x2 - x1)
  }

  return distance
}

const buildCoordinates = (
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  squigglySize: number,
  squigglyCount: number,
  vertical: boolean
): number[][] => {
  const coordinates = Array.from({ length: squigglyCount }, (_, i) => {
    if (vertical) {
      return [x1, y1 + i * squigglySize]
    } else {
      return [x1 + i * squigglySize, y1]
    }
  })

  return coordinates
}

const Squiggly: React.FunctionComponent<SquigglyProps> = ({
  x1,
  y1,
  x2,
  y2,
  flipped = false,
}) => {
  let vertical = false
  const squigglySize = 40
  if (x1 === x2) {
    vertical = true
  }

  const distance = calculate_distance(x1, y1, x2, y2, vertical)

  const squigglyCount = Math.floor(distance / squigglySize)
  const coordinates = buildCoordinates(
    x1,
    y1,
    x2,
    y2,
    squigglySize,
    squigglyCount,
    vertical
  )

  const squigglyArray = coordinates.map((coordinate, i) => {
    if (i === coordinates.length - 1) {
      return null
    }

    const [x1, y1] = coordinate
    const [x2, y2] = coordinates[i + 1]

    let peakPoint = 0
    const peakSize = flipped ? -squigglySize / 2 : squigglySize / 2

    if (vertical) {
      if (i % 2 === 0) {
        peakPoint = x1 + peakSize
      } else {
        peakPoint = x1 - peakSize
      }
    } else {
      if (i % 2 === 0) {
        peakPoint = y1 - peakSize
      } else {
        peakPoint = y1 + peakSize
      }
    }

    const peakCoordinate = vertical
      ? { x: peakPoint, y: y1 + squigglySize / 2 }
      : { x: x1 + squigglySize / 2, y: peakPoint }

    if (i % 2 === 0) {
      return (
        <path
          key={i}
          d={`M ${x1} ${y1} Q ${peakCoordinate.x} ${peakCoordinate.y} ${x2} ${y2}`}
          stroke="black"
          fillOpacity="0"
          strokeWidth="4"
        ></path>
      )
    } else {
      return (
        <path
          key={i}
          d={`M ${x1} ${y1} Q ${peakCoordinate.x} ${peakCoordinate.y} ${x2} ${y2}`}
          stroke="black"
          fillOpacity="0"
          strokeWidth="4"
        ></path>
      )
    }
  })

  return <>{squigglyArray}</>
}
const InvitationShow: React.FunctionComponent<InvitationShowProps> = ({
  invitation,
  address,
  sendToName,
}) => {
  return (
    <div>
      <svg
        height="555"
        width="400"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        {/* <line
          x1="199"
          y1="0"
          x2="199"
          y2="700"
          stroke="black"
          strokeWidth={1}
        /> */}
        <text
          x="10"
          y="15"
          fontFamily="monospace"
          fontSize="14px"
        >{`${sendToName}`}</text>
        <line x1="10" y1="20" x2="200" y2="20" stroke="black" strokeWidth={1} />

        <text x="10" y="35" fontFamily="monospace" fontSize="14px">
          {address.street1}
        </text>
        <line x1="10" y1="40" x2="200" y2="40" stroke="black" strokeWidth={1} />
        <text x="10" y="55" fontFamily="monospace" fontSize="14px">
          {address.street2}
        </text>
        <line x1="10" y1="60" x2="200" y2="60" stroke="black" strokeWidth={1} />
        <text x="10" y="75" fontFamily="monospace" fontSize="14px">{`${
          address.city
        }, ${address.state.toUpperCase()} ${address.zip}`}</text>
        <line x1="10" y1="80" x2="200" y2="80" stroke="black" strokeWidth={1} />
        <circle cx="19" cy="285" r="5" />
        <circle cx="19" cy="290" r="5" />
        <text x="133" y="200" fontFamily="Glorify" fontSize="14px">
          YOU ARE INVITED TO
        </text>
        <text
          x="140"
          y="220"
          textAnchor="center"
          fontFamily="Glorify"
          fontSize="14px"
        >
          THE WEDDING OF
        </text>
        <text x="110" y="265" fontFamily="Glorify" fontSize="36px">
          Ian & Annie
        </text>
        <text
          x="139"
          y="300"
          textAnchor="center"
          fontFamily="Glorify"
          fontSize="14px"
        >
          SEPTEMBER 7, 2024
        </text>
        <text
          x="120"
          y="320"
          textAnchor="center"
          fontFamily="Glorify"
          fontSize="14px"
        >
          SUNRISE AMPHITHEATER
        </text>
        <text
          x="127"
          y="340"
          textAnchor="center"
          fontFamily="Glorify"
          fontSize="14px"
        >
          BOULDER, COLORADO
        </text>
        <text
          x="181"
          y="438"
          textAnchor="center"
          fontFamily="Glorify"
          fontSize="14px"
        >
          RSVP
        </text>
        <text
          x="115"
          y="550"
          textAnchor="center"
          fontFamily="monospace"
          fontSize="17px"
        >
          ianandannie.party
        </text>
      </svg>
      <svg
        height="700"
        width="400"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <QRCodeSVG
          value={`https://www.ianandannie.party/invitations/${invitation.id}/rsvps/new?token=${invitation.token}`}
          height="80"
          width="80"
          x="158"
          y="550"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        />
      </svg>
    </div>
  )
}

export default InvitationShow
