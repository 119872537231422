import React, { useState } from 'react'
import Form from '../../components/form/form'
import Input from '../../components/form/input'

const RsvpForm = ({ rsvp, responses }) => {
  const csrf =
    document
      .querySelector("meta[name='csrf-token']")
      ?.getAttribute('content') || ''
  const [currentRsvp, setCurrentRsvp] = useState(rsvp)
  const [currentResponses, setCurrentResponses] = useState(responses)
  const [guestFirstName, setGuestFirstName] = useState('')
  const [guestLastName, setGuestLastName] = useState('')
  const { id } = currentRsvp || {}
  const queryPrams = window.location.search
  const token = new URLSearchParams(queryPrams).get('token') || 'invalid_token'
  const submitLabel = 'Submit RSVP'

  const handleGuestFirstName = (event) => {
    setGuestFirstName(event.target.value)
  }

  const handleGuestLastName = (event) => {
    setGuestLastName(event.target.value)
  }

  const handleGuestName = (inviteeId) => async (event) => {
    event.preventDefault()

    const guestData = new FormData()
    guestData.append('invitee[first_name]', guestFirstName)
    guestData.append('invitee[last_name]', guestLastName)

    await fetch(`/invitees/${inviteeId}`, {
      method: 'PATCH',
      headers: {
        'X-CSRF-Token': csrf,
      },
      body: guestData,
    })
    const response = await fetch(
      `/invitations/${currentRsvp.invitationId}/rsvps/${id}?token=${token}`,
      {
        method: 'GET',
        headers: {
          'X-CSRF-Token': csrf,
        },
      }
    )

    const responseData = await response.json()

    setCurrentRsvp(responseData.rsvp)
    setCurrentResponses(
      responseData.responses.map((response) => ({
        id: response.id,
        inviteeId: response.invitee_id,
        firstName: response.first_name,
        lastName: response.last_name,
        going: response.going,
        dietaryRestrictions: response.dietary_restrictions,
      }))
    )
  }

  const action = id
    ? `/invitations/${currentRsvp.invitationId}/rsvps/${id}`
    : `/invitations/${currentRsvp.invitationId}/rsvps`
  const method = id ? 'patch' : 'post'

  return (
    <Form
      title="RSVP"
      action={action}
      success="Your RSVP has been submitted. Thank you!"
      error={'There was an error submitting your RSVP. Please try again.'}
      submitLabel={submitLabel}
      method={method}
      key={`${currentRsvp.invitationId}-form`}
    >
      <Input
        type="hidden"
        model="invitation"
        field="[token]"
        value={token}
        label=""
        id={`${currentRsvp.invitationId}-token`}
      />
      <Input
        type="hidden"
        model="rsvp"
        field="[id]"
        value={id}
        label=""
        id={`${currentRsvp.invitationId}-id`}
      />
      <Input
        type="hidden"
        model="rsvp"
        field="[invitation_id]"
        value={currentRsvp.invitationId}
        label=""
        id={`${currentRsvp.invitationId}-invitation-id`}
      />
      <div>
        <h1 className="text-med text-gold my-4 lg:text-2xl lg:my-4">
          Who will be attending?
        </h1>
        <p className="text-sm text-gold mb-8">
          Please RSVP by July 24th, 2024.
        </p>
      </div>
      {currentResponses.map((response, i) => (
        <div key={`response-${i}`} className="mb-4 w-full">
          <Input
            id={`${response.id}-id`}
            label=""
            type="hidden"
            model="rsvp"
            field={`[responses_attributes][${i}][id]`}
            value={response.id}
          />
          <Input
            id={`${response.inviteeId}-invitee-id`}
            label=""
            type="hidden"
            model="rsvp"
            field={`[responses_attributes][${i}][invitee_id]`}
            value={response.inviteeId}
          />

          {response.firstName == 'Guest' ? (
            <>
              <Input
                type="checkbox"
                id={`${response.id}-going`}
                label="Guest"
                model="rsvp"
                field={`[responses_attributes][${i}][going]`}
                checkedValues={['no', 'yes']}
                inputClassName="text-sm appearance-none w-4 h-4 border-2 border-gold rounded-full bg-pink-300 checked:bg-gold shadow-green shadow-sm shadow-rounded lg:h-4 lg:w-4"
                className="flex text-sm"
                value={response.going}
              />
              <div className="mb-4">
                <div className="">
                  <div>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 mb-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
                      type="text"
                      placeholder="Guest first name"
                      onChange={handleGuestFirstName}
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      placeholder="Guest last name"
                      onChange={handleGuestLastName}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
                    />
                  </div>
                </div>
              </div>
              <button
                onClick={handleGuestName(response.inviteeId)}
                className="bg-gold hover:bg-green text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mb-4"
              >
                Save Guest Name
              </button>
            </>
          ) : (
            <Input
              type="checkbox"
              id={`${response.id}-going`}
              label={`${response.firstName} ${response.lastName}`}
              model="rsvp"
              field={`[responses_attributes][${i}][going]`}
              checkedValues={['no', 'yes']}
              inputClassName="text-sm appearance-none w-4 h-4 border-2 border-gold rounded-full bg-pink-300 checked:bg-gold shadow-green shadow-sm shadow-rounded lg:h-4 lg:w-4"
              className="flex text-sm"
              value={response.going}
            />
          )}
          <Input
            id={`${response.inviteeId}-dietary-restrictions`}
            type="text"
            model="rsvp"
            field={`[responses_attributes][${i}][dietary_restrictions]`}
            label={`Dietary Restrictions for ${response.firstName || 'Guest'}`}
            placeholder="None"
            value={response.dietaryRestrictions}
          />
        </div>
      ))}
    </Form>
  )
}

export default RsvpForm
