import React, { useState } from 'react'
import Form from '../form/form'
import Input from '../form/input'
import { Typeahead } from 'react-typeahead'
import AddressForm, { Address } from './address'

type Invitee = {
  id: number
  name: string
}

type Invitation = {
  id: number
  phoneNumber: string
  sendTo: string
}

type InvitationFormProps = {
  id?: number
  invitees: Invitee[]
  mapsKey: string
  phone?: string
  invitation: Invitation
  invitationInvitees: Invitee[]
  invitationAddress: Address
}

const defaultAddress = (address: Address) => ({
  id: address?.id || '',
  street1: address?.street1 || '',
  street2: address?.street2 || '',
  city: address?.city || '',
  state: address?.state || '',
  zip: address?.zip || '',
})

const InvitationForm: React.FunctionComponent<InvitationFormProps> = ({
  invitation,
  invitationInvitees = [],
  invitationAddress,
  invitees,
  mapsKey,
}) => {
  const { id, phoneNumber: phone, sendTo } = invitation || {}
  const title = id ? 'Edit Invitation' : 'New Invitation'
  const action = id ? `/invitations/${id}` : '/invitations'
  const method = id ? 'patch' : 'post'
  const success = id ? 'Invitation Updated!' : 'Invitation Added!'
  const submitLabel = id ? 'Update Invitation' : 'Add Invitation'
  const [currentInvitees, setCurrentInvitees] =
    useState<Invitee[]>(invitationInvitees)
  const [searchableInvitees, setSearchableInvitees] =
    useState<Invitee[]>(invitees)
  const [currentAddress, setCurrentAddress] = useState<Address>(
    defaultAddress(invitationAddress)
  )
  const [phoneNumber, setPhoneNumber] = useState<string>(phone)
  const [sendToName, setSendToName] = useState<string>(sendTo)

  const onOptionSelected = (option: Invitee, event) => {
    if (currentInvitees.includes(option)) return
    setCurrentInvitees([...currentInvitees, option])
    setSearchableInvitees(searchableInvitees.filter((i) => i !== option))
    const targetInput = event.currentTarget.parentElement.previousElementSibling
    targetInput.blur()
    targetInput.focus()
    setTimeout(() => {
      targetInput.value = ''
    }, 500)
  }

  const reset = () => {
    if (!id) {
      setCurrentInvitees([])
      setSearchableInvitees(invitees)
      setSendToName('')
      setCurrentAddress({
        id: '',
        street1: '',
        street2: '',
        city: '',
        state: '',
        zip: '',
      })
      setPhoneNumber('')
    }
  }

  return (
    <Form
      title={title}
      action={action}
      method={method}
      success={success}
      error="There was an error submitting the form. Please try again."
      submitLabel={submitLabel}
      reset={reset}
    >
      <Input
        label=""
        type="hidden"
        model="invitation"
        field="[id]"
        id="invitation_id"
        value={id}
      />
      <Input
        label=""
        type="hidden"
        model="invitation"
        field="[invitee_ids]"
        id="invitation_invitee_ids"
        value={currentInvitees.map((invitee) => invitee.id).join(',')}
      />
      <Input
        label="Phone"
        model="invitation"
        field="[phone_number]"
        type="tel"
        id="invitation_phone_number"
        value={phoneNumber}
      />
      <div className="flex">
        <div className="mr-2 text-gold">Add Invitee:</div>
        <Typeahead
          className="mb-4 text-gold"
          options={searchableInvitees}
          maxVisible={2}
          filterOption={'name'}
          displayOption={'name'}
          onOptionSelected={onOptionSelected}
        />
      </div>
      <h3 className="text-gold">Invitees</h3>
      {currentInvitees.length > 0 && (
        <div className="w-1/2 border-2 border-gold mb-2 rounded">
          <div className="p-2">
            <ul>
              {currentInvitees.map((invitee) => {
                return (
                  <li className="text-gold" key={invitee.id}>
                    {invitee.name}
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      )}

      <div className="w-full">
        <label
          htmlFor="invitation[tier]"
          className="block text-gold text-sm font-bold mb-2"
        >
          Tier
        </label>
        <select name="invitation_tier" id="invitation[tier]">
          <option value={0}>One</option>
          <option value={1}>Two</option>
          <option value={2}>Three</option>
        </select>
      </div>

      <Input
        type="text"
        model="invitation"
        field="[send_to]"
        label="Send To"
        value={sendToName}
        id="invitation[send_to]"
      />
      <AddressForm
        mapsKey={mapsKey}
        currentAddress={currentAddress}
        setCurrentAddress={setCurrentAddress}
      />
    </Form>
  )
}

export default InvitationForm
