import React from 'react'
import ReactDOM from 'react-dom/client'

type Components = Record<string, React.ElementType>

const snakeToCamel = (str: string): string => {
  const camelboi = str.replace(/([-_]\w)/g, (g) => g[1].toUpperCase())
  return camelboi
}

const camelizeProps = (props: Record<string, any>): Record<string, any> => {
  return Object.entries(props).reduce((acc, [key, value]) => {
    if (
      typeof value === 'string' ||
      typeof value === 'number' ||
      value == null
    ) {
      acc[snakeToCamel(key)] = value
    } else {
      if (Array.isArray(value)) {
        acc[snakeToCamel(key)] = value.map((v) => camelizeProps(v))
        return acc
      }
      acc[snakeToCamel(key)] = camelizeProps(value as Record<string, any>)
    }
    return acc as Record<string, any>
  }, {})
}

export default function mount(components: Components): void {
  document.addEventListener('DOMContentLoaded', () => {
    const mountPoints = document.querySelectorAll('[data-react-component]')
    mountPoints.forEach((mountPoint) => {
      const { dataset } = mountPoint as HTMLElement
      const componentName = dataset.reactComponent
      if (componentName) {
        const Component = components[componentName]
        if (Component) {
          const props = camelizeProps(JSON.parse(dataset.props as string))
          const root = ReactDOM.createRoot(mountPoint)
          root.render(<Component {...props} />)
        } else {
          console.warn(
            'WARNING: No component found for: ',
            dataset.reactComponent,
            components
          )
        }
      }
    })
  })
}
