import React from 'react'
import Form from '../form/form'
import Input from '../form/input'

type InviteeFormProps = {
  first_name: string
  last_name: string
  id?: number
}

const InviteeForm: React.FunctionComponent<InviteeFormProps> = ({
  first_name,
  last_name,
  id,
}) => {
  const title = id ? 'Edit Invitee' : 'New Invitee'
  const action = id ? `/invitees/${id}` : '/invitees'
  const method = id ? 'patch' : 'post'
  const success = id ? 'Invitee Updated!' : 'Invitee Added!'
  const submitLabel = id ? 'Update Invitee' : 'Add Invitee'

  return (
    <Form
      title={title}
      action={action}
      method={method}
      success={success}
      error="There was an error submitting the form. Please try again."
      submitLabel={submitLabel}
    >
      <Input
        label=""
        type="hidden"
        model="invitee"
        field="[id]"
        id="invitee_id"
        value={id}
      />
      <Input
        label="First Name"
        model="invitee"
        field="[first_name]"
        type="text"
        id="invitee_first_name"
        required
        value={first_name}
      />
      <Input
        label="Last Name"
        model="invitee"
        field="[last_name]"
        type="text"
        id="invitee_last_name"
        required
        value={last_name}
      />
    </Form>
  )
}

export default InviteeForm
